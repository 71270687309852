html,
body {
  background-color: $whitesmoke;
  min-height: 100%;
  font-size: 1em;
  margin: 0;
  color: $dark-grey;
}

img {
  width: 100%;
}

.has-navbar-fixed-top {
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1 0;
}

.section {
  padding-top: 10px;
  padding-bottom: 10px;

  h1 {
    margin-bottom: 10px;
  }
}

.row {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.image-container {
  position: relative;
  max-width: 52px;
  max-height: 52px;
  min-width: 52px;
  min-height: 52px;
  width: auto;
  height: auto;
  overflow: hidden;
  border-radius: 2px;
  margin-right: 20px;
  flex-shrink: 0;
  padding: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-color: $lightblue;
}