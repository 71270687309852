.tiles {
  .summary-row {
    .text {
      margin-left: 30px;
      justify-content: center;

      .title {
        flex: 0;
      }
    }
  }
}