@import url(https://fonts.googleapis.com/css?family=PT+Mono|PT+Sans:400,700);
html,
body {
  background-color: #fafafa;
  min-height: 100%;
  font-size: 1em;
  margin: 0;
  color: #212121; }

img {
  width: 100%; }

.has-navbar-fixed-top {
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1 0; }

.section {
  padding-top: 10px;
  padding-bottom: 10px; }
  .section h1 {
    margin-bottom: 10px; }

.row {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  width: 100%; }

.image-container {
  position: relative;
  max-width: 52px;
  max-height: 52px;
  min-width: 52px;
  min-height: 52px;
  width: auto;
  height: auto;
  overflow: hidden;
  border-radius: 2px;
  margin-right: 20px;
  flex-shrink: 0;
  padding: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-color: #f4f8f9; }

.page-wrapper {
  height: 100%;
  max-height: 100%;
  overflow: hidden; }
  .page-wrapper .container {
    height: 100%;
    max-height: 100%; }
    .page-wrapper .container .columns {
      height: 100%;
      max-height: 100%;
      overflow: hidden; }

.right-align {
  justify-content: flex-end;
  text-align: right; }

.left-align {
  justify-content: flex-start;
  text-align: left; }

body,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "PT Sans", sans-serif !important;
  font-weight: normal !important; }

button,
input,
textarea,
th {
  font-family: "PT Mono", monospace !important;
  font-weight: normal !important; }

.main {
  width: 100%;
  margin-top: 56px;
  height: calc(100vh - 56px); }

.collection-grid .column {
  padding: 0.4rem; }

.container.grid-lg {
  max-width: 100%; }

.active-item {
  height: 100%;
  overflow: hidden; }

.sixtiles-section .sixtiles-panels .section-panel.menu {
  flex: 0 1; }

.tile-templates-field {
  position: relative;
  height: 100%;
  padding-bottom: 40px;
  overflow: hidden; }
  .tile-templates-field .box-header {
    flex: 1 1;
    width: 100%;
    padding: 0 20px 10px;
    background: white; }
    .tile-templates-field .box-header .btn-action {
      background: white; }
  .tile-templates-field .lists {
    overflow: scroll;
    position: relative;
    height: 100%; }

.tiles .summary-row .text {
  margin-left: 30px;
  justify-content: center; }
  .tiles .summary-row .text .title {
    flex: 0 1; }

.navbar {
  background: #2196f3;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000; }
  .navbar .navbar-section:first-child {
    height: 56px;
    width: 100%; }
  .navbar .navbar-section:not(:first-child):last-child {
    justify-content: center; }
  .navbar .btn-action {
    margin-right: 16px; }

.navbar-section {
  width: 100%;
  max-width: 1140px; }
  .navbar-section .column {
    align-items: center;
    flex-direction: row; }

.navbar-brand {
  width: 150px;
  display: inline-block;
  box-sizing: content-box;
  height: 100%;
  flex: 1 0 auto;
  display: flex;
  align-items: center; }

.search {
  width: 100%;
  height: 36px; }
  .search input {
    background: #42a5f5;
    border: none;
    color: #fff;
    width: 100%;
    height: 100%;
    padding: 9px 8px;
    letter-spacing: 0;
    border-radius: 2px; }
    .search input::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      opacity: 0.7;
      color: #fff; }
    .search input::-moz-placeholder {
      /* Firefox 19+ */
      opacity: 0.7;
      color: #fff; }
    .search input:-ms-input-placeholder {
      /* IE 10+ */
      opacity: 0.7;
      color: #fff; }
    .search input:-moz-placeholder {
      /* Firefox 18- */
      opacity: 0.7;
      color: #fff; }
  .search .form-input:focus {
    border: none;
    box-shadow: none; }

.navbar .user {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.navbar-user-menu-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: center; }
  .navbar-user-menu-inner .user-name {
    margin-left: 5px; }

.navbar-user-wrapper.btn {
  background: transparent !important; }

.navbar-user-menu .navbar-user-menu-paper {
  top: 50px; }

.navbar-user-menu.button {
  display: inline-block;
  padding: 0; }

.avatar {
  border: 1px solid #ffffff;
  height: 32px;
  width: 32px;
  background-color: #e0e0e0; }

.btn.btn-action {
  border-radius: 100%;
  width: 32px;
  height: 32px;
  font-size: 13px; }
  .btn.btn-action:focus {
    box-shadow: none; }
  .btn.btn-action.btn-st-primary {
    background-color: #42a5f5;
    border: none;
    color: #fff; }
  .btn.btn-action.btn-st-outline {
    background-color: transparent;
    border: 1px dashed #42a5f5;
    color: #42a5f5; }
    .btn.btn-action.btn-st-outline:hover {
      border-color: #1b93f3;
      color: #1b93f3; }

.tab {
  justify-content: center;
  align-items: flex-end;
  margin: 0;
  border: none;
  height: 56px;
  flex-wrap: nowrap; }

.tab-item {
  margin: 0 15px; }
  .tab-item::after {
    border-bottom: 2px solid #fff;
    transition: 0.5s ease;
    content: '';
    display: block;
    width: 0; }
  .tab-item:hover::after {
    width: 100%; }
  .tab-item.active::after {
    width: 100%; }
  .tab-item button {
    padding: 10px 15px;
    color: #fff;
    letter-spacing: -0.03px;
    text-align: center;
    background-color: transparent;
    border: none;
    white-space: nowrap;
    cursor: pointer; }
    .tab-item button:focus, .tab-item button:active {
      border: none;
      outline: 0; }

.title {
  flex: 1 1;
  font-family: "PT Mono", monospace;
  font-size: 1.1rem;
  font-weight: 600;
  vertical-align: middle;
  margin-bottom: 0;
  display: flex;
  align-items: center; }
  .title .badge {
    margin-left: 10px; }
  .title h1 {
    font-family: "PT Mono", monospace;
    font-size: 1.1rem;
    font-weight: 600;
    vertical-align: middle;
    margin-bottom: 0;
    white-space: nowrap; }

.cell-image {
  display: flex;
  justify-content: center; }
  .cell-image .cell-image-parent {
    margin: 0;
    margin-right: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    margin-right: 0;
    min-width: 100%;
    display: flex;
    background: none; }
  .cell-image .image-container {
    margin: 0;
    margin-right: 0;
    margin: 0;
    margin-right: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .cell-image .image-container img {
      max-width: 52px;
      max-height: 52px; }

.cell-radio {
  padding: 0 !important; }
  .cell-radio .cell-radio-container {
    width: 52px;
    flex: 1 1;
    position: relative;
    display: flex;
    overflow: auto;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding-right: 24px;
    padding-left: 24px;
    min-height: 52px; }

.list-current-tile-templates.delete-item .list-icon {
  background-color: #bdbdbd;
  color: #e0e0e0; }

.list-current-tile-templates.delete-item .list-label {
  color: #bdbdbd; }

.list-current-tile-templates.delete-item .btn-action {
  border-color: #bdbdbd !important;
  color: #bdbdbd !important; }

.summary-row {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 10px; }
  .summary-row .form-header-meta-actions {
    margin-left: 20px; }
  .summary-row .summary-header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    height: 48px; }
    .summary-row .summary-header .summary-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-bottom: 10px; }
  .summary-row .summary-body {
    display: flex;
    flex-direction: row; }
  .summary-row .btn.btn-action {
    border-color: #757575;
    color: #757575; }
    .summary-row .btn.btn-action:hover {
      border-color: #616161;
      color: #616161; }
  .summary-row .image-container {
    display: flex;
    align-content: center;
    align-items: center; }
    .summary-row .image-container .image-inner {
      height: auto;
      max-width: 52px;
      max-height: 52px; }
  .summary-row .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1 1; }
    .summary-row .text h6.subtitle {
      color: #757575;
      font-family: "PT Mono", monospace;
      margin-top: .5em; }

.label-toggle {
  background: rgba(233, 239, 244, 0.48);
  padding: 5px;
  border-radius: 4px;
  display: flex;
  justify-content: stretch; }
  .label-toggle .label {
    cursor: pointer;
    flex: 0.5 1;
    display: flex;
    align-items: center;
    padding: 11px;
    color: #757575; }
    .label-toggle .label svg {
      color: #757575;
      margin-right: 11px; }
  .label-toggle .label.active {
    color: #212121;
    border-radius: 3px;
    background: #ffffff;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.03); }
    .label-toggle .label.active svg {
      color: #2196f3; }

.card-template-preview {
  margin: 20px; }

.card {
  background: #fff;
  height: 100%;
  border: 0;
  border-radius: 6px;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 250ms;
  font-size: 0.8em;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  .card:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
  .card .card-header {
    display: flex;
    flex-direction: column;
    padding: 0.8rem; }
  .card .card-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
  .card .card-title {
    margin-bottom: 10px; }
  .card .card-subtitle {
    color: #757575; }

.card-body {
  padding: 0.8rem; }

.card-image {
  position: relative;
  width: 100%;
  height: 140px;
  overflow: hidden;
  border-radius: 2px;
  margin-right: 22px;
  padding: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-color: #f4f8f9; }

.card-footer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  background-color: #fafafa;
  margin-top: 20px; }
  .card-footer .btn {
    margin-right: 10px; }
    .card-footer .btn:last-child {
      margin-right: 0; }

.card-group {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px; }
  .card-group:last-child {
    margin-bottom: 0; }

.hex-color {
  display: flex;
  width: 60px;
  flex-direction: column; }

.collection-color {
  display: block;
  height: 25px;
  width: 100%;
  border-radius: 2px;
  flex: 1 1; }

.collection-color-code {
  text-transform: uppercase;
  text-align: center;
  font-family: "PT Mono", monospace;
  color: #bdbdbd;
  transition: all 250ms; }

.card-preview {
  margin: 20px;
  height: 350px;
  width: 200px;
  min-height: 350px;
  min-width: 200px; }
  .card-preview .card-content {
    flex: 1 1;
    padding: 20px;
    justify-content: center; }
  .card-preview .field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px; }
  .card-preview .field-value {
    margin-left: 5px; }
  .card-preview .field-text {
    flex: 1 1; }
  .card-preview .int {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center; }
    .card-preview .int .field-value {
      border: 1px solid #757575;
      width: 50px;
      height: 38px;
      font-size: 28px;
      text-align: center;
      margin-left: 0; }
    .card-preview .int .field-text {
      flex-direction: column-reverse;
      display: flex; }
    .card-preview .int .field-name {
      font-size: 20px;
      margin-top: 10px; }
  .card-preview .image-container {
    height: 100px;
    width: 100%;
    max-height: 100px;
    max-width: 100%;
    display: flex;
    justify-content: center; }
    .card-preview .image-container .image-inner {
      height: 100%;
      width: auto; }
  .card-preview .last-updated {
    padding: 20px;
    padding-top: 0; }

.btn.btn-st {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: none;
  background-color: #42a5f5;
  color: #fff;
  transition: all 0.25s ease-in-out;
  align-items: center;
  margin-right: 10px;
  height: 32px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  .btn.btn-st:disabled {
    cursor: not-allowed;
    pointer-events: all !important; }
  .btn.btn-st:last-child {
    margin-right: 0; }
    .btn.btn-st:last-child.toolbar-selected-button {
      margin: 16px; }
  .btn.btn-st:hover {
    background-color: #1b93f3; }
  .btn.btn-st svg {
    margin-right: 10px; }

.btn.btn-warning {
  background-color: #ffc107; }
  .btn.btn-warning:hover {
    background-color: #dda600; }

.btn.btn-save.disable {
  opacity: 0.5; }

.btn.btn-error {
  background-color: #f44336; }
  .btn.btn-error:hover {
    background-color: #f21f0f; }
  .btn.btn-error.hide {
    display: none; }

.btn.btn-icon-only {
  justify-content: center;
  min-width: 32px;
  border-radius: 50px; }
  .btn.btn-icon-only svg {
    margin-right: 0; }
  .btn.btn-icon-only.btn-ghost {
    border-radius: 50px;
    height: 32px;
    padding: 0; }

.switch-primary-color {
  background-color: 'green'; }

.btn.btn-transparent {
  background: none;
  box-shadow: none;
  padding: 0; }
  .btn.btn-transparent:hover {
    background: none;
    box-shadow: none; }
    .btn.btn-transparent:hover svg {
      color: #212121; }
  .btn.btn-transparent svg {
    color: #757575;
    font-size: 18px; }

.btn.btn-ghost {
  background: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: none;
  color: #bdbdbd;
  transition: all 0.25s ease-in-out;
  align-items: center;
  margin-right: 8px;
  box-shadow: none;
  padding: 11px;
  height: auto; }
  .btn.btn-ghost:last-child {
    margin-right: 0; }
  .btn.btn-ghost:hover {
    background-color: #bdbdbd;
    color: #757575;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

.dialog-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 10px 40px;
  z-index: 9998; }
  .dialog-header h1 {
    flex: 1 1;
    text-align: center;
    margin-bottom: 0;
    font-family: "PT Mono", monospace;
    font-size: 1.1rem;
    font-weight: 600; }
  .dialog-header .dialog-actions {
    display: flex;
    justify-content: flex-end;
    flex: 0.25 1; }
    .dialog-header .dialog-actions .btn {
      height: auto;
      width: 90px; }

.flex-spacer {
  display: flex;
  flex: 0.25 1; }

.dialog-body {
  margin-top: 60px; }

.dialog {
  max-width: 1140px;
  width: 100%; }

.csv-reader-input {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 38px;
  background-color: white;
  color: #757575;
  font-size: 0.8rem;
  line-height: 1.2rem;
  border-radius: 3px;
  overflow: hidden;
  cursor: text;
  border: 1px solid #e0e0e0; }

.csv-reader-input input {
  /* sembunyikan dengan `opacity:0` */
  opacity: 0;
  filter: alpha(opacity=0);
  /* pastikan elemen ini selalu berada di sebelah atas */
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 2000%;
  z-index: 4;
  cursor: pointer; }

.csv-reader-input span {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 10px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .csv-reader-input span.placeholder {
    color: #bcc3ce; }

.csv-reader-input span + span {
  left: auto;
  background-color: #e0e0e0;
  border-radius: 0 3px 3px 0;
  padding: 0 15px; }

/* Dora the Explorer */
.csv-reader-input input::-ms-value {
  display: none; }

.csv-reader-input input::-ms-browse {
  display: block;
  margin: 0;
  padding: 0;
  cursor: inherit; }

.form {
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 20px;
  border-radius: 4px; }
  .form .imgurl,
  .form .imgurlDefault {
    flex-direction: row;
    align-items: center; }
    .form .imgurl .form-label-wrapper,
    .form .imgurlDefault .form-label-wrapper {
      width: auto;
      margin-bottom: 0; }
    .form .imgurl .image,
    .form .imgurlDefault .image {
      flex-direction: column;
      justify-content: flex-start;
      align-self: center; }
    .form .imgurl .show-editor-text,
    .form .imgurlDefault .show-editor-text {
      text-transform: uppercase;
      font-family: "PT Mono", monospace;
      font-size: 0.6em;
      align-self: flex-start;
      padding: 0;
      flex: 1 1;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex-direction: column; }
  .form .box-header {
    padding: 0 0 10px 0; }
    .form .box-header .form-label {
      margin-bottom: 0; }

.form-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px; }
  .form-header h1 {
    text-align: center;
    font-family: "PT Mono", monospace;
    font-size: 1.1rem;
    font-weight: 600; }
  .form-header .dialog-actions,
  .form-header .form-header-actions {
    display: flex;
    justify-content: flex-end;
    flex: 0.25 1;
    align-items: center; }
  .form-header .dialog-actions {
    display: flex;
    justify-content: flex-end;
    flex: 0.25 1; }
    .form-header .dialog-actions .btn {
      height: auto;
      width: 90px; }

.form-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0; }
  .form-footer .btn {
    width: 80px; }
    .form-footer .btn.secondary {
      background-color: #bdbdbd; }

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px !important;
  position: relative; }
  .form-group .form-label-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 12px; }
  .form-group.collectionId form-label-wrapper, .form-group.categoryId form-label-wrapper {
    min-width: 86px; }
  .form-group.s3Url {
    flex-direction: row;
    align-items: center; }
    .form-group.s3Url .form-label-wrapper {
      margin-bottom: 0; }
  .form-group.collectionId, .form-group.categoryId {
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    .form-group.collectionId .form-label-wrapper, .form-group.categoryId .form-label-wrapper {
      width: 100px; }
    .form-group.collectionId .box-header, .form-group.categoryId .box-header {
      margin-left: 10px; }

.form-label {
  text-transform: uppercase;
  font-family: "PT Mono", monospace;
  font-size: 0.8em;
  align-self: center;
  padding: 0; }

.form-label-invalid {
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  flex: 1 1; }
  .form-label-invalid svg {
    color: red; }

.invalid-message-popover {
  color: red; }

.form-input {
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  padding: 10px 20px;
  height: 45px;
  transition: border 0.25s ease-in-out;
  background: white !important; }
  .form-input:focus {
    border: 1px solid #2196f3;
    outline: none;
    box-shadow: none; }
  .form-input.is-danger {
    border: 1px solid #f44336; }

.form-input-select {
  width: 100%; }
  .form-input-select.is-danger .select__control {
    border: 1px solid #f44336 !important; }

.select__placeholder {
  position: absolute;
  top: 50%;
  left: 0;
  margin: 0;
  padding: 0;
  font-family: "PT Mono", monospace;
  font-size: 0.8rem;
  line-height: 1.2rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #bcc3ce !important; }

.select__indicator {
  padding: 0 0 0 20px !important; }

.select__clear-indicator {
  padding-right: 20px !important; }

.select__single-value {
  font-family: "PT Mono", monospace;
  font-size: 0.8rem;
  line-height: 1.2rem; }

.select__control {
  border: 1px solid #e0e0e0 !important;
  border-radius: 2px !important;
  padding: 10px 20px;
  height: 45px;
  transition: border 0.25s ease-in-out; }

.select__control--is-focused {
  box-shadow: none !important;
  border-color: #2196f3 !important; }

.select__value-container {
  padding: 0 !important;
  font-size: 0.8rem;
  line-height: 1.2rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  position: relative; }

.material-picker {
  height: auto !important;
  width: 100% !important;
  box-shadow: none !important; }

.text-area {
  resize: vertical;
  display: block; }

.row.vertical {
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .row.vertical .tile-edit {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .row.vertical .tile-edit .category-images {
      margin: 0; }
  .row.vertical.image {
    justify-content: "center";
    align-items: "center";
    align-content: "center";
    align-self: "center";
    position: "relative";
    display: "flex"; }
  .row.vertical .image-container {
    margin-bottom: 5px; }

.mui-switch.disabled {
  cursor: auto;
  cursor: initial; }

.sixtile-badge {
  font-size: 11px;
  color: white;
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding: 2.5px;
  margin-left: 10px; }
  .sixtile-badge.invalid {
    height: 20px;
    align-self: center;
    margin-right: 20px;
    background-color: #f44336; }

.box {
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  position: relative; }

.box-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex: 1 1;
  width: 100%; }
  .box-header .btn-action svg {
    color: #42a5f5; }

.box-subtitle {
  text-transform: uppercase;
  font-family: "PT Mono", monospace;
  font-size: 0.8em; }

.list {
  list-style: none;
  margin: 0;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 20px 20px; }

.list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px; }
  .list-item:first-child {
    margin-top: 0; }
  .list-item.is-clickable {
    cursor: pointer; }
    .list-item.is-clickable:hover {
      background-color: #f4f8f9; }

.list-icon {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  background-color: #2196f3;
  color: #b2dbfb;
  margin-right: 20px; }

.list-label {
  flex: 1 1;
  font-size: 0.8em; }

.popover {
  position: absolute;
  right: 0;
  top: 64px;
  z-index: 2;
  width: 400px;
  min-height: 180px;
  height: auto;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border: solid 1px #e0e0e0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1 1; }
  .popover .popover-header {
    font-family: "PT Mono", monospace;
    font-size: 14px;
    line-height: 16px;
    height: 16px;
    margin-bottom: 16px;
    width: 100%;
    text-transform: uppercase; }
  .popover .popover-body {
    flex: 1 1;
    display: flex;
    flex-direction: column; }
    .popover .popover-body .inline-search {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 0;
      position: relative; }
      .popover .popover-body .inline-search .input-search {
        transition: width 1s ease-in-out; }
      .popover .popover-body .inline-search.active .input-search {
        position: relative;
        width: 100%;
        height: 18px;
        padding: 0 0 6px 0;
        margin-left: 0;
        text-indent: 0;
        z-index: 0;
        border-bottom: 2px solid #757575; }
        .popover .popover-body .inline-search.active .input-search:focus {
          outline: none;
          border-bottom-color: #2196f3;
          color: #2196f3; }
      .popover .popover-body .inline-search .inline-search-results {
        position: absolute;
        top: 45px;
        left: 0;
        width: 352px;
        z-index: 3;
        background-color: #ffffff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
        .popover .popover-body .inline-search .inline-search-results .list {
          padding: 21px 24px; }
        .popover .popover-body .inline-search .inline-search-results .list-item {
          height: 24px;
          padding: 0 0 0 0;
          margin-bottom: 25px; }
          .popover .popover-body .inline-search .inline-search-results .list-item:last-child {
            margin-bottom: 0; }
  .popover .popover-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    align-self: flex-end; }
    .popover .popover-footer .btn {
      margin-right: 8px;
      width: 100px; }

.tag-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 17px;
  flex: 1 1; }

.tag {
  position: relative;
  font-family: "PT Mono", monospace;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 0;
  flex-wrap: none;
  margin-right: 8px;
  margin-bottom: 8px; }
  .tag:last-child {
    margin-right: 0; }
  .tag .item-icon {
    position: absolute;
    left: 0;
    top: 0;
    margin-right: 0; }
  .tag .tag-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 28px;
    padding-left: 40px;
    padding-right: 8px;
    background-color: #ededed;
    border-radius: 100px; }
  .tag .tag-remove {
    margin-left: 12px;
    cursor: pointer; }
    .tag .tag-remove svg {
      color: #212121; }

.confirm-button {
  display: flex; }

.popout {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(27, 28, 28, 0.34);
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.5s, opacity 0.5s linear; }
  .popout .container {
    height: auto;
    max-height: unset;
    overflow: unset; }
  .popout.is-visible {
    visibility: visible;
    opacity: 1; }
    .popout.is-visible .popout-inner {
      width: auto;
      opacity: 1;
      min-width: 300px;
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      right: 0; }
  .popout .popout-inner {
    position: absolute;
    background-color: #fff;
    top: 0;
    bottom: 0;
    width: 0;
    right: -1000px;
    opacity: 0;
    transition: all 1s ease-in-out;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    padding: 20px;
    max-height: 100vh;
    overflow-y: scroll; }
  .popout h1,
  .popout h2,
  .popout h3,
  .popout h4,
  .popout h5,
  .popout h6 {
    text-align: center; }
  .popout h2 {
    margin-bottom: 50px; }

.popout .form {
  width: 400px;
  box-shadow: none;
  padding: 0;
  background-color: transparent; }

.prompt {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(27, 28, 28, 0.34);
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.5s, opacity 0.5s linear; }
  .prompt.is-visible {
    visibility: visible;
    opacity: 1; }
    .prompt.is-visible .prompt-content {
      width: 326px;
      opacity: 1;
      min-width: 300px;
      -webkit-transform: translateX(-50%) translateY(-50%);
              transform: translateX(-50%) translateY(-50%);
      top: 50%; }

.prompt-content {
  position: absolute;
  background-color: #fff;
  border-radius: 4px;
  top: -150vh;
  left: 50%;
  height: 180px;
  width: 0;
  opacity: 0;
  transition: all 1s ease-in-out;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 20px;
  display: flex;
  flex-direction: column; }

.prompt-title {
  font-size: 1.2rem;
  line-height: 1.6rem; }

.prompt-message {
  font-family: "PT Mono", monospace;
  color: #757575;
  padding: 17px 0;
  font-size: 0.8rem;
  line-height: 1.4rem; }

.prompt-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1 1; }
  .prompt-actions .btn {
    margin-right: 40px; }
    .prompt-actions .btn:last-child {
      margin-right: 0; }
  .prompt-actions .btn-transparent {
    color: #2196f3; }
  .prompt-actions .btn-transparent.secondary {
    color: #757575; }

.empty-message {
  padding: 30px 0; }
  .empty-message h3 {
    font-family: "PT Mono", monospace;
    text-align: center;
    color: #757575; }

.radio-container {
  display: flex;
  justify-content: flex-start;
  flex: 1 1; }

.radio-disabled-container {
  display: flex;
  flex: 1 1;
  justify-content: flex-start;
  margin-left: 24px; }

.radio-disabled {
  height: 16px;
  width: 16px;
  background-color: red;
  border-radius: 100%; }
  .radio-disabled.checked {
    background-color: green; }

.sixtiles-section {
  max-width: 100%;
  flex: 1 1;
  max-height: 100%; }
  .sixtiles-section .sixtiles-panels {
    display: flex;
    flex-direction: row;
    max-height: 100%;
    justify-content: center; }
    .sixtiles-section .sixtiles-panels .section-panel {
      max-width: 622px;
      max-height: 100%;
      margin-left: 20px;
      flex: 1 1;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      border-radius: 4px;
      margin-bottom: 0;
      border-radius: 4px; }
      .sixtiles-section .sixtiles-panels .section-panel:first-child {
        margin-left: 0; }
      .sixtiles-section .sixtiles-panels .section-panel:nth-last-child {
        margin-right: 20px; }
      .sixtiles-section .sixtiles-panels .section-panel.placeholder {
        background-color: #e0e0e0;
        height: 100%; }
      .sixtiles-section .sixtiles-panels .section-panel.table {
        min-width: 360px;
        overflow: hidden; }
        .sixtiles-section .sixtiles-panels .section-panel.table.collaspe {
          min-width: 150px;
          overflow: hidden; }
      .sixtiles-section .sixtiles-panels .section-panel .item-wrapper {
        overflow: auto; }
        .sixtiles-section .sixtiles-panels .section-panel .item-wrapper.placeholder {
          height: 100%; }
          .sixtiles-section .sixtiles-panels .section-panel .item-wrapper.placeholder .section-panel-inner {
            height: 100%; }
      .sixtiles-section .sixtiles-panels .section-panel .item-wrapper,
      .sixtiles-section .sixtiles-panels .section-panel .active-item {
        max-height: 100%;
        min-height: 100%;
        background-color: white;
        display: flex;
        flex-direction: column; }
      .sixtiles-section .sixtiles-panels .section-panel .data-table {
        max-height: 100%;
        min-height: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1 1; }
    .sixtiles-section .sixtiles-panels .add-form.form-panel {
      background: #fff;
      margin-top: 0;
      padding: 20px; }
      .sixtiles-section .sixtiles-panels .add-form.form-panel .container {
        padding: 0; }
      .sixtiles-section .sixtiles-panels .add-form.form-panel .TileTemplates .list {
        padding: 0; }
      .sixtiles-section .sixtiles-panels .add-form.form-panel .typeId {
        flex-direction: row-reverse; }
        .sixtiles-section .sixtiles-panels .add-form.form-panel .typeId .box-header {
          margin-left: 10px;
          justify-self: center;
          align-self: center;
          padding: 0; }
      .sixtiles-section .sixtiles-panels .add-form.form-panel .form {
        box-shadow: none;
        padding: 0;
        border-radius: 0; }
      .sixtiles-section .sixtiles-panels .add-form.form-panel .form-header {
        margin-bottom: 20px; }
        .sixtiles-section .sixtiles-panels .add-form.form-panel .form-header h1 {
          margin-bottom: 0;
          text-align: left; }

.color-picker-preview {
  color: #fff;
  line-height: 11px;
  display: inline-flex;
  justify-content: center;
  height: 100%;
  width: 50px; }

.color-picker-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }
  .color-picker-wrapper .color-picker {
    border-right: 1px solid #e0e0e0; }
  .color-picker-wrapper .top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #e0e0e0;
    height: 45px; }
  .color-picker-wrapper .form-input {
    height: auto;
    border: none;
    min-width: 0; }

.batch-common-features {
  display: flex;
  flex-direction: row; }

.batch-modal-content.modal-content {
  display: flex;
  flex-direction: row;
  padding: 0;
  font-family: "PT Mono", monospace;
  font-size: 0.8rem;
  line-height: 1.2rem;
  min-width: 600px; }
  .batch-modal-content.modal-content .batch-modal-lhs,
  .batch-modal-content.modal-content .batch-modal-rhs {
    width: 50%;
    flex: 1 1; }
  .batch-modal-content.modal-content .batch-modal-rhs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    border-left: 0.5px solid #fafafa; }
    .batch-modal-content.modal-content .batch-modal-rhs .tile-preview {
      height: 100%;
      display: flex;
      flex: 1 1;
      justify-content: center; }
    .batch-modal-content.modal-content .batch-modal-rhs textarea {
      flex: 1 1;
      border: none;
      background: #fafafa;
      color: #757575;
      resize: none;
      padding: 16px; }
      .batch-modal-content.modal-content .batch-modal-rhs textarea:focus {
        outline: none; }
  .batch-modal-content.modal-content .batch-modal-lhs {
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 16px; }
    .batch-modal-content.modal-content .batch-modal-lhs .container {
      padding: 0; }
    .batch-modal-content.modal-content .batch-modal-lhs .batch-modal-option,
    .batch-modal-content.modal-content .batch-modal-lhs .form {
      margin-top: 16px; }
    .batch-modal-content.modal-content .batch-modal-lhs .form {
      box-shadow: none;
      padding: 0; }
      .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.s3Url,
      .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.apiUrl,
      .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.collectionId,
      .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.categoryId {
        flex-direction: column; }
        .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.s3Url .box-header,
        .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.apiUrl .box-header,
        .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.collectionId .box-header,
        .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.categoryId .box-header {
          display: none; }
        .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.s3Url .form-input-select,
        .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.apiUrl .form-input-select,
        .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.collectionId .form-input-select,
        .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.categoryId .form-input-select {
          height: 38px;
          position: relative; }
          .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.s3Url .form-input-select .select__control,
          .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.apiUrl .form-input-select .select__control,
          .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.collectionId .form-input-select .select__control,
          .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.categoryId .form-input-select .select__control {
            height: 38px;
            padding: 6px;
            justify-content: center;
            align-items: center; }
          .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.s3Url .form-input-select .select__value-container,
          .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.apiUrl .form-input-select .select__value-container,
          .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.collectionId .form-input-select .select__value-container,
          .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.categoryId .form-input-select .select__value-container {
            height: unset; }
      .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.hexColour,
      .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.nounHexColour,
      .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.textHexColour {
        flex-direction: row;
        justify-content: flex-start; }
        .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.hexColour .form-label-invalid,
        .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.nounHexColour .form-label-invalid,
        .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.textHexColour .form-label-invalid {
          display: none; }
        .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.hexColour .form-input,
        .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.nounHexColour .form-input,
        .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.textHexColour .form-input {
          display: none; }
        .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.hexColour .form-label-wrapper,
        .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.nounHexColour .form-label-wrapper,
        .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.textHexColour .form-label-wrapper {
          display: flex;
          margin-bottom: 0;
          width: auto;
          margin-right: 10px; }
        .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.hexColour .color-picker-wrapper,
        .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.nounHexColour .color-picker-wrapper,
        .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.textHexColour .color-picker-wrapper {
          height: 20px; }
          .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.hexColour .color-picker-wrapper .color-picker,
          .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.nounHexColour .color-picker-wrapper .color-picker,
          .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.textHexColour .color-picker-wrapper .color-picker {
            border: none; }
          .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.hexColour .color-picker-wrapper .top,
          .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.nounHexColour .color-picker-wrapper .top,
          .batch-modal-content.modal-content .batch-modal-lhs .form .form-group.textHexColour .color-picker-wrapper .top {
            width: 20px;
            height: 20px; }
      .batch-modal-content.modal-content .batch-modal-lhs .form .form-label-wrapper {
        display: none; }

.modal-actions .btn {
  background-color: #42a5f5;
  border: none;
  color: #fff; }

.modal-actions .duplicates-label {
  flex: 1 1;
  margin-right: 10px;
  font-size: 14px;
  color: #757575; }

.job-progress-bar {
  position: fixed;
  width: 100%;
  z-index: 1300;
  bottom: 0;
  display: flex;
  flex-direction: row;
  background-color: #303232;
  max-height: 150px;
  overflow: auto;
  padding: 10px;
  align-content: center;
  justify-content: center; }
  .job-progress-bar .text {
    color: #fff;
    display: flex;
    font-size: 14px;
    margin-right: 10px;
    line-height: 20px;
    align-items: center; }
  .job-progress-bar .view-details {
    font-weight: 500;
    color: #42a5f5;
    line-height: 21px;
    font-size: 14px;
    display: flex;
    align-items: center; }
    .job-progress-bar .view-details:hover {
      cursor: pointer;
      color: #128ef2; }
  .job-progress-bar .progresss-bar-label {
    display: flex;
    flex-direction: row;
    padding: 5px 10px 5px 30px;
    padding-bottom: 0;
    min-width: 150px;
    line-height: 20px;
    padding-top: 0; }

.progress-bar-container {
  width: 100%;
  display: flex;
  align-items: center; }

.progress-bar {
  padding: 0 10px 4px 0;
  width: 100%;
  padding-bottom: 0; }

.completed-fill {
  display: flex;
  height: 20px;
  vertical-align: middle;
  width: 100%;
  position: relative;
  background: #757575;
  border-radius: 2px; }

.progress-fill {
  background-color: #2196f3;
  height: 20px;
  border-radius: 2px;
  transition: width .2s ease-in-out; }

.percentage-text {
  position: absolute;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  white-space: nowrap; }

.progress-bar-cancel-icon {
  width: 10%; }

.job-details-modal {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  min-height: 200px; }
  .job-details-modal span {
    margin-bottom: 15px;
    padding-left: 20px; }
  .job-details-modal .items {
    display: flex;
    flex-direction: column;
    max-height: 150px;
    overflow: scroll;
    background: #fafafa;
    margin: 20px;
    margin-top: 0; }
    .job-details-modal .items .failure-item {
      color: #717171; }

.pack-template .st-background-image img {
  height: auto;
  max-width: 100%; }

.pack-templates-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
  padding-right: 24px;
  padding-left: 24px; }
  .pack-templates-header .pack-templates-header-title {
    flex: 1 1;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em; }
  .pack-templates-header .pack-templates-actions {
    display: flex;
    flex-direction: row; }

.pack-templates {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
  margin: 10px; }
  .pack-templates .pack-template {
    --margin-size: 10px;
    flex-basis: calc(25% - var($margin-size));
    flex-basis: fill;
    margin-right: 10px;
    margin-top: 10px;
    display: flex;
    height: 150px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: calc(25% - var(--margin-size));
    max-width: calc(25% - var(--margin-size));
    position: relative;
    border-radius: 4px; }
    .pack-templates .pack-template:nth-child(4n) {
      margin-right: 0; }
    .pack-templates .pack-template.dummy {
      visibility: hidden; }
    .pack-templates .pack-template:hover {
      cursor: pointer; }
      .pack-templates .pack-template:hover .pack-template-img-overlay {
        opacity: 0.85; }
    .pack-templates .pack-template .pack-template-name {
      text-align: center;
      position: absolute;
      bottom: 0;
      color: #fff;
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 16x;
      max-width: 100px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      overflow: hidden;
      white-space: nowrap; }
    .pack-templates .pack-template .pack-template-img {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 2px;
      padding: 0;
      background-size: cover;
      background-position: 50% 50%;
      background-color: #f4f8f9; }
    .pack-templates .pack-template .pack-template-img-overlay {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.77;
      transition: opacity 0.5s ease-in;
      background-image: linear-gradient(to bottom, transparent 0%, #222 100%); }
    .pack-templates .pack-template .pack-template-edit-button {
      position: absolute; }

.card-template .summary-row {
  border: none;
  padding-bottom: 0; }

.column.col-6 {
  flex: 1 1; }
  .column.col-6 #img {
    flex: 1 1; }

.form-group.fields {
  min-height: 210px;
  justify-content: flex-start; }
  .form-group.fields .select__menu {
    max-height: 150px; }
  .form-group.fields .box-header {
    flex: 0 1; }
  .form-group.fields .form-input-select {
    margin-bottom: 10px; }
  .form-group.fields .lists {
    width: 100%; }
    .form-group.fields .lists .list {
      padding: 0;
      width: 100%; }

.field-templates-field-form-add {
  align-self: flex-end; }

.field-inline-form {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .field-inline-form .btn-action {
    margin-right: 10px; }
    .field-inline-form .btn-action:last-child {
      margin-right: 0; }
  .field-inline-form .field-inline-form-inner-header {
    flex: 1 1;
    display: flex;
    align-items: center; }
    .field-inline-form .field-inline-form-inner-header .name {
      margin-right: 10px; }
  .field-inline-form .field-inline-form-header {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px; }
  .field-inline-form .field-inline-form-property {
    flex: 1 1; }
  .field-inline-form.collapsed {
    width: 60%;
    flex: 1 1; }
  .field-inline-form.delete-item .btn-action {
    border-color: #bdbdbd !important;
    color: #bdbdbd !important; }
    .field-inline-form.delete-item .btn-action svg {
      border-color: #bdbdbd !important;
      color: #bdbdbd !important; }
  .field-inline-form .field-inline-form-properties {
    width: 100%; }
    .field-inline-form .field-inline-form-properties .field-inline-form-property {
      margin-bottom: 10px; }

