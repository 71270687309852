// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=PT+Mono|PT+Sans:400,700');
// Colors
$white: #fff;
$whitesmoke: #fafafa; // #f5f5f5 - background
$lightest-grey: #e0e0e0; // #e0e0e0 - divider
$lighter-grey: #bdbdbd; // #bdbdbd - hint text
$grey: #757575; // #757575 - secondary text, icons
$dark-grey: #212121; // #212121 - text
$black: #000;
$gold: #ffd700;
$brand: #2196f3;
$brand-secondary: #42a5f5;
$lightblue: #f4f8f9;
$errorColor: #f44336;
$successColor: #4caf50;
$warningColor: #ffc107;
$overlayColor: rgba(27, 28, 28, 0.34);
// Dimensions
$navbar-height: 56px;
$input-border-radius: 2px;
$contentWidth: 1140px;
// Tile 
$default-tile-color: #d80666; //#32cfb2;
$tile-color: lighten($default-tile-color, 50%);
$tile-width: 125px; //large: 125px , small: 110px
$tile-height: $tile-width * 0.922;
$tile-clippath: polygon(28.364% 0,
  73.455% 0,
  100% 38.955%,
  71.636% 100%,
  26.545% 100%,
  0 61.095%);
$family-condensed: 'Roboto Condensed',
sans-serif;
// Update Bulma's global variables
$family-sans-serif: $family-condensed;
$family-primary: $family-sans-serif;
$family-secondary: 'PT Sans',
sans-serif;
$actionFont: $family-secondary;