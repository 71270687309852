.prompt {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $overlayColor;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.5s, opacity 0.5s linear;
  &.is-visible {
    visibility: visible;
    opacity: 1;
    .prompt-content {
      width: 326px;
      opacity: 1;
      min-width: 300px;
      transform: translateX(-50%) translateY(-50%);
      top: 50%;
    }
  }
}

.prompt-content {
  position: absolute;
  background-color: $white;
  border-radius: 4px;
  top: -150vh;
  left: 50%;
  height: 180px;
  width: 0;
  opacity: 0;
  transition: all 1s ease-in-out;
  transform: translateX(-50%);
  @include box_shadow(2);
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.prompt-title {
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.prompt-message {
  font-family: $actionFont;
  color: $grey;
  padding: 17px 0;
  font-size: 0.8rem;
  line-height: 1.4rem;
}

.prompt-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;
  .btn {
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
    }
  }
  .btn-transparent {
    color: $brand;
  }
  .btn-transparent.secondary {
    color: $grey;
  }
}
