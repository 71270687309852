.color-picker-preview {
  color: #fff; // fill: currentColor;
  line-height: 11px;
  display: inline-flex;
  justify-content: center;
  height: 100%;
  width: 50px;
}

.color-picker-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .color-picker {
    border-right: 1px solid #e0e0e0;

  }

  .top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #e0e0e0;
    height: 45px;
  }

  .form-input {
    height: auto;

    border: none;
    min-width: 0;
  }
}