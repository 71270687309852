.popover {
  position: absolute;
  right: 0;
  top: 64px;
  z-index: 2;
  width: 400px;
  min-height: 180px;
  height: auto;
  border-radius: 4px;
  background-color: #ffffff;
  @include box_shadow(2);
  border: solid 1px $lightest-grey;
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  .popover-header {
    font-family: $actionFont;
    font-size: 14px;
    line-height: 16px;
    height: 16px;
    margin-bottom: 16px;
    width: 100%;
    text-transform: uppercase;
  }
  .popover-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    .inline-search {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 0;
      position: relative;
      .input-search {
        transition: width 1s ease-in-out;
      }
      &.active {
        .input-search {
          position: relative;
          width: 100%;
          height: 18px;
          padding: 0 0 6px 0;
          margin-left: 0;
          text-indent: 0;
          z-index: 0;
          border-bottom: 2px solid $grey;
          &:focus {
            outline: none;
            border-bottom-color: $brand;
            color: $brand;
          }
        }
      }
      .inline-search-results {
        position: absolute;
        top: 45px;
        left: 0;
        width: 352px;
        z-index: 3;
        background-color: #ffffff;
        @include box_shadow(1);
        .list {
          padding: 21px 24px;
        }
        .list-item {
          height: 24px;
          padding: 0 0 0 0;
          margin-bottom: 25px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .popover-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    align-self: flex-end;
    .btn {
      margin-right: 8px;
      width: 100px;
    }
  }
}

.tag-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 17px;
  flex: 1;
}

.tag {
  position: relative;
  font-family: $actionFont;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 0;
  flex-wrap: none;
  margin-right: 8px;
  margin-bottom: 8px;
  &:last-child {
    margin-right: 0;
  }
  .item-icon {
    position: absolute;
    left: 0;
    top: 0;
    margin-right: 0;
  }
  .tag-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 28px;
    padding-left: 40px;
    padding-right: 8px;
    background-color: #ededed;
    border-radius: 100px;
  }
  .tag-remove {
    margin-left: 12px;
    cursor: pointer;
    svg {
      color: $dark-grey;
    }
  }
}
