.sixtiles-section {
  max-width: 100%;
  flex: 1;
  max-height: 100%;

  .sixtiles-panels {
    display: flex;
    flex-direction: row;
    max-height: 100%;
    // min-height: 100%;
    justify-content: center;

    .section-panel {
      max-width: 622px;
      max-height: 100%;
      margin-left: 20px;
      flex: 1;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      border-radius: 4px; // margin: 20px;
      margin-bottom: 0;
      border-radius: 4px;

      &:first-child {
        margin-left: 0;
      }

      &:nth-last-child {
        margin-right: 20px;
      }

      &.placeholder {
        background-color: $lightest-grey;
        height: 100%;
      }

      &.table {
        min-width: 360px;
        overflow: hidden;

        &.collaspe {
          min-width: 150px;
          overflow: hidden;
        }
      }

      .item-wrapper {
        overflow: auto;

        &.placeholder {
          height: 100%;

          .section-panel-inner {

            height: 100%;
          }
        }
      }



      .item-wrapper,
      .active-item {
        max-height: 100%;
        min-height: 100%;
        background-color: white; // height: 100%;
        display: flex;
        flex-direction: column;
        // align-items: center;
      }

      .data-table {
        max-height: 100%;
        min-height: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }

    .add-form {
      &.form-panel {
        background: #fff;
        margin-top: 0;
        padding: 20px;

        .container {
          padding: 0;
        }

        .TileTemplates {
          .list {
            padding: 0;
          }
        }

        .typeId {
          flex-direction: row-reverse;

          .box-header {
            margin-left: 10px;
            justify-self: center;
            align-self: center;
            padding: 0;
          }
        }

        .form {
          box-shadow: none;
          padding: 0;
          border-radius: 0;
        }

        .form-header {
          margin-bottom: 20px;

          h1 {
            margin-bottom: 0;
            text-align: left;
          }
        }
      }
    }
  }
}

.table {
  th {
    // min-width: 80px;
  }
}