.list {
  list-style: none;
  margin: 0;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 20px 20px;
}

.list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  &:first-child {
    margin-top: 0;
  }
  &.is-clickable {
    cursor: pointer;
    &:hover {
      background-color: $lightblue;
    }
  }
}

.list-icon {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  background-color: $brand;
  color: lighten($brand, 30%);
  margin-right: 20px;
}

.list-label {
  flex: 1;
  font-size: 0.8em;
}
