.job-progress-bar {
  position: fixed;
  width: 100%;
  z-index: 1300;
  bottom: 0;
  display: flex;
  flex-direction: row;
  background-color: #303232;
  max-height: 150px;
  overflow: auto;
  padding: 10px;


  align-content: center;
  justify-content: center;

  .text {
    color: #fff;
    display: flex;
    font-size: 14px;
    margin-right: 10px;
    line-height: 20px;
    align-items: center;
  }

  .view-details {
    font-weight: 500;
    color: $brand-secondary;
    line-height: 21px;
    font-size: 14px;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      color: darken($brand-secondary, 10%);
    }
  }

  .progresss-bar-label {
    display: flex;
    flex-direction: row;
    padding: 5px 10px 5px 30px;
    padding-bottom: 0;
    min-width: 150px;
    line-height: 20px;
    padding-top: 0;

  }
}


.progress-bar-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.progress-bar {
  padding: 0 10px 4px 0;
  width: 100%;
  padding-bottom: 0;
}

.completed-fill {
  display: flex;
  height: 20px;
  vertical-align: middle;
  width: 100%;
  position: relative;
  background: $grey;
  border-radius: 2px;
}


.progress-fill {
  background-color: $brand;
  height: 20px;
  border-radius: 2px;
  // margin-top: 1px;

  transition: width .2s ease-in-out;
}



.percentage-text {
  position: absolute;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  // top: -5px;
  white-space: nowrap;
}


.progress-bar-cancel-icon {
  width: 10%;
}