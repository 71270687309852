$card-preview-width: 200px;
$card-preview-height: 350px;
$card-padding: 20px;


.card-preview {
  margin: $card-padding;
  height: $card-preview-height;
  width: $card-preview-width;

  min-height: $card-preview-height;
  min-width: $card-preview-width;


  .card-content {
    flex: 1;
    padding: $card-padding;
    justify-content: center;
  }

  .field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;

    flex-direction: row;

    align-items: center;
    margin-bottom: 5px;
  }

  .varchar {}

  .field-value {
    margin-left: 5px;
  }

  .field-text {
    flex: 1;
  }

  .int {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;

    .field-value {
      border: 1px solid $grey;
      width: 50px;
      height: 38px;
      font-size: 28px;
      text-align: center;
      margin-left: 0;
    }

    .field-text {
      flex-direction: column-reverse;
      display: flex;
    }

    .field-name {
      font-size: 20px;
      margin-top: 10px;
    }
  }

  .image-container {
    height: 100px;
    width: 100%;
    max-height: 100px;
    max-width: 100%;
    display: flex;
    justify-content: center;

    .image-inner {
      height: 100%;
      width: auto;
    }
  }

  .last-updated {
    padding: $card-padding;
    padding-top: 0;
  }

}