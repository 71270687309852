.page-wrapper {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  .container {
    height: 100%;
    max-height: 100%;
    // overflow: hidden;
    .columns {
      height: 100%;
      max-height: 100%;
      overflow: hidden;
    }
  }
}