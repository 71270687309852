.card-template-preview {
  margin: 20px;
}


$card-padding: 0.8rem;

.card {
  background: $white;
  height: 100%;
  border: 0;
  border-radius: 6px;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 250ms;
  font-size: 0.8em;
  cursor: pointer;
  @include box_shadow(1);

  &:hover {
    @include box_shadow(2);
  }

  .card-header {
    display: flex;
    flex-direction: column;
    padding: $card-padding;
  }

  .card-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .card-title {
    margin-bottom: 10px;
  }

  .card-subtitle {
    color: $grey;
  }
}

.card-body {
  padding: $card-padding;
}

.card-image {
  position: relative;
  width: 100%;
  height: 140px;
  overflow: hidden;
  border-radius: 2px;
  margin-right: 22px;
  padding: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-color: $lightblue;
}

.card-footer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  background-color: $whitesmoke;
  margin-top: 20px;

  .btn {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.card-group {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.hex-color {
  display: flex;
  width: 60px;
  flex-direction: column;
}

.collection-color {
  display: block;
  height: 25px;
  width: 100%;
  border-radius: 2px;
  flex: 1;
}

.collection-color-code {
  text-transform: uppercase;
  text-align: center;
  font-family: $actionFont;
  color: $lighter-grey;
  transition: all 250ms;
}