.popout {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $overlayColor;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.5s, opacity 0.5s linear;
  .container {
    height: auto;
    max-height: unset;
    overflow: unset;
  }
  &.is-visible {
    visibility: visible;
    opacity: 1;
    .popout-inner {
      width: auto;
      opacity: 1;
      min-width: 300px; // transition-delay: 0s;
      transform: scaleX(1);
      right: 0;
    }
  }
  .popout-inner {
    position: absolute;
    background-color: $white;
    top: 0;
    bottom: 0;
    width: 0;
    right: -1000px;
    opacity: 0;
    transition: all 1s ease-in-out;
    @include box_shadow(2);
    padding: 20px;
    max-height: 100vh;
    overflow-y: scroll;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
  }
  h2 {
    margin-bottom: 50px;
  }
}

.popout .form {
  width: 400px;
  box-shadow: none;
  padding: 0;
  background-color: transparent;
}