.field-templates-field-form-add {
  align-self: flex-end;
}



.field-inline-form {
  width: 100%;
  display: flex;
  flex-direction: column;



  .btn-action {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  .field-inline-form-inner-header {
    flex: 1 1;
    display: flex;
    align-items: center;

    .name {
      margin-right: 10px;
    }
  }

  .field-inline-form-header {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .field-inline-form-property {
    flex: 1;
  }

  &.collapsed {
    width: 60%;
    flex: 1;
  }

  &.delete-item {
    .btn-action {
      svg {
        border-color: #bdbdbd !important;
        color: #bdbdbd !important;
      }

      border-color: #bdbdbd !important;
      color: #bdbdbd !important;
    }
  }

  .field-inline-form-properties {
    width: 100%;

    .field-inline-form-property {
      margin-bottom: 10px;
    }
  }

}