.card-template {
  .summary-row {
    border: none;
    padding-bottom: 0;
  }
}

.column.col-6 {
  flex: 1;

  #img {
    flex: 1;
  }
}

.form-group.fields {
  min-height: 210px;
  justify-content: flex-start;

  .select__menu {
    max-height: 150px;
  }

  .box-header {
    flex: 0
  }

  .form-input-select {
    margin-bottom: 10px;
  }



  .lists {
    width: 100%;

    .list {
      padding: 0;
      width: 100%;
    }
  }

  .form-input-select {}
}