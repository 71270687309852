.btn.btn-action {
  border-radius: 100%;
  width: 32px;
  height: 32px;
  font-size: 13px;
  &:focus {
    box-shadow: none;
  }
  &.btn-st-primary {
    background-color: $brand-secondary;
    border: none;
    color: $white;
  }
  &.btn-st-outline {
    background-color: transparent;
    border: 1px dashed $brand-secondary;
    color: $brand-secondary;
    &:hover {
      border-color: darken($brand-secondary, 8%);
      color: darken($brand-secondary, 8%);
    }
  }
}