.job-details-modal {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  min-height: 200px;

  span {
    margin-bottom: 15px;
    padding-left: 20px;
  }

  .items {
    display: flex;
    flex-direction: column;
    max-height: 150px;
    overflow: scroll;
    background: lighten($lightest-grey, 10%);


    margin: 20px;
    margin-top: 0;

    .failure-item {
      color: darken($lighter-grey, 30%);
    }


  }
}