$margin-size: 10px;

.pack-template {
  .st-background-image img {
    height: auto;
    max-width: 100%;
  }
}

.pack-templates-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: $margin-size;
  padding-right: 24px;
  padding-left: 24px;

  .pack-templates-header-title {
    flex: 1;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
  }

  .pack-templates-actions {
    display: flex;
    flex-direction: row;
  }
}

.pack-templates {
  display: flex;
  flex-wrap: wrap;

  align-items: flex-start;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
  margin: $margin-size;

  .pack-template {
    --margin-size: 10px;
    flex-basis: calc(25% - var($margin-size));
    flex-basis: fill;
    margin-right: $margin-size;
    margin-top: $margin-size;
    display: flex;
    height: 150px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: calc(25% - var(--margin-size));
    max-width: calc(25% - var(--margin-size));
    position: relative;

    border-radius: 4px;

    &:nth-child(4n) {
      margin-right: 0;
    }

    // &:last-child {
    //   margin-bottom: 20px;
    // }
    &.dummy {
      visibility: hidden;
    }

    &:hover {
      cursor: pointer;

      .pack-template-img-overlay {
        opacity: 0.85;

      }
    }




    .pack-template-name {
      text-align: center;
      position: absolute;
      bottom: 0;
      color: $white;
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 16x;
      max-width: 100px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      overflow: hidden;
      white-space: nowrap;

    }

    .pack-template-img {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 2px;

      padding: 0;
      background-size: cover;
      background-position: 50% 50%;
      background-color: $lightblue;
    }

    .pack-template-img-overlay {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.77;
      transition: opacity 0.5s ease-in;

      background-image: linear-gradient(to bottom, transparent 0%, #222 100%);

    }

    .pack-template-edit-button {
      position: absolute;
    }

  }
}