.collection-grid {
  .column {
    padding: 0.4rem;
  }
}

.container.grid-lg {
  max-width: 100%;
}

.active-item {
  height: 100%;
  overflow: hidden;
  // align-items: flex-start !important;
}

.sixtiles-section {
  .sixtiles-panels {
    .section-panel {

      &.menu {
        flex: 0;
      }
    }
  }
}

.tile-templates-field {
  position: relative;

  height: 100%;
  padding-bottom: 40px;
  overflow: hidden;

  .box-header {
    flex: 1 1;
    width: 100%;
    padding: 0 20px 10px;
    background: white;

    .btn-action {
      background: white;
    }
  }

  .lists {
    overflow: scroll;

    position: relative;
    height: 100%;
  }
}