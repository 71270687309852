.csv-reader-input {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 38px;
  background-color: white;
  color: $grey;
  font-size: 0.8rem;
  line-height: 1.2rem;
  border-radius: 3px;
  overflow: hidden;
  cursor: text;
  border: 1px solid $lightest-grey;
}

.csv-reader-input input {
  /* sembunyikan dengan `opacity:0` */
  opacity: 0;
  filter: alpha(opacity=0);
  /* pastikan elemen ini selalu berada di sebelah atas */
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 2000%;
  z-index: 4;
  cursor: pointer;
}

.csv-reader-input span {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 10px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &.placeholder {
    color: #bcc3ce
  }
}

.csv-reader-input span+span {
  left: auto;
  background-color: $lightest-grey;
  border-radius: 0 3px 3px 0;
  padding: 0 15px;
  // box-shadow: 0 0 3px black, 0 0 10px black;
}


/* Dora the Explorer */

.csv-reader-input input::-ms-value {
  display: none
}

.csv-reader-input input::-ms-browse {
  display: block;
  margin: 0;
  padding: 0;
  cursor: inherit;
}