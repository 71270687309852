.navbar {
  background: $brand;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  .navbar-section:first-child {
    height: $navbar-height;
    width: 100%;
  }
  .navbar-section:not(:first-child):last-child {
    justify-content: center;
  }
  .btn-action {
    margin-right: 16px;
  }
}

.navbar-section {
  width: 100%;
  max-width: $contentWidth;
  .column {
    align-items: center;
    flex-direction: row;
  }
}

.navbar-brand {
  width: 150px;
  display: inline-block;
  box-sizing: content-box;
  height: 100%;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
}

.search {
  width: 100%;
  height: 36px;
  input {
    background: $brand-secondary;
    border: none;
    color: $white;
    width: 100%;
    height: 100%;
    padding: 9px 8px;
    letter-spacing: 0;
    border-radius: $input-border-radius;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      opacity: 0.7;
      color: $white;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      opacity: 0.7;
      color: $white;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      opacity: 0.7;
      color: $white;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      opacity: 0.7;
      color: $white;
    }
  }
  .form-input:focus {
    border: none;
    box-shadow: none;
  }
}

.navbar {
  .user {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
.navbar-user-menu-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: center;
  .user-name {
    margin-left: 5px;
  }
}
.navbar-user-wrapper {
 &.btn {
   background: transparent !important;
 }
}
.navbar-user-menu {
  .navbar-user-menu-paper {
    top: 50px;
  }
  &.button {
    display: inline-block;
    padding: 0;
  }
}
