.dialog-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  padding: 10px 40px;
  z-index: 9998; // @include box_shadow(1);

  h1 {
    flex: 1;
    text-align: center;
    margin-bottom: 0;
    font-family: $actionFont;
    font-size: 1.1rem;
    font-weight: 600;
  }

  .dialog-actions {
    display: flex;
    justify-content: flex-end;
    flex: 0.25;

    .btn {
      height: auto;
      width: 90px;
    }
  }
}

.flex-spacer {
  display: flex;
  flex: 0.25;
}

.dialog-body {
  margin-top: 60px;
}

.dialog {
  max-width: 1140px;
  width: 100%;
}