.title {
  flex: 1;
  font-family: $actionFont;
  font-size: 1.1rem;
  font-weight: 600;
  vertical-align: middle;
  margin-bottom: 0;
  display: flex;
  align-items: center;

  .badge {
    margin-left: 10px;
  }


  h1 {
    font-family: $actionFont;
    font-size: 1.1rem;
    font-weight: 600;
    vertical-align: middle;
    margin-bottom: 0;
    white-space: nowrap;

  }
}

.cell-image {
  display: flex;
  justify-content: center;

  .cell-image-parent {
    margin: 0;
    margin-right: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    margin-right: 0;
    min-width: 100%;
    display: flex;
    background: none;
  }

  .image-container {
    margin: 0;
    margin-right: 0;

    margin: 0;
    margin-right: 0;
    display: flex;

    justify-content: center;
    align-items: center;

    img {
      max-width: 52px;
      max-height: 52px;
    }
  }
}

.cell-radio {
  padding: 0 !important;

  .cell-radio-container {
    width: 52px;
    // margin-left: 12px;
    flex: 1;
    position: relative;
    display: flex;
    overflow: auto;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding-right: 24px;
    padding-left: 24px;
    min-height: 52px; // height of image
  }
}

.list-new-tile-templates {
  // .btn-action {
  //   border-color: $lighter-grey !important;
  //   color: $lighter-grey !important;
  // }
}

.list-current-tile-templates {

  // .btn-action {
  //   border-color: $errorColor !important;
  //   color: $errorColor !important;
  // }
  &.delete-item {
    .list-icon {
      background-color: $lighter-grey;
      color: $lightest-grey;
    }

    .list-label {
      color: $lighter-grey;
    }

    .btn-action {
      border-color: $lighter-grey !important;
      color: $lighter-grey !important;
    }
  }
}

.summary-row {
  width: 100%;
  background-color: $white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $lightest-grey;
  margin-bottom: 10px;

  .form-header-meta-actions {
    margin-left: 20px;
  }

  .summary-header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    height: 48px;

    .summary-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }

  .summary-body {
    display: flex;
    flex-direction: row;
  }

  .btn.btn-action {
    border-color: $grey;
    color: $grey;

    &:hover {
      border-color: darken($grey, 8%);
      color: darken($grey, 8%);
    }
  }

  .image-container {
    display: flex;
    align-content: center;
    align-items: center;

    .image-inner {
      height: auto;
      max-width: 52px;
      max-height: 52px;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1;

    h6.subtitle {
      color: $grey;
      font-family: $actionFont;
      margin-top: .5em;
    }
  }
}