.tab {
  justify-content: center;
  align-items: flex-end;
  margin: 0;
  border: none;
  height: 56px;
  flex-wrap: nowrap;
}

.tab-item {
  margin: 0 15px; // min-width: 140px;

  &::after {
    border-bottom: 2px solid $white;
    transition: 0.5s ease;
    content: '';
    display: block;
    width: 0;
  }

  &:hover::after {
    width: 100%;
  }

  &.active {
    &::after {
      width: 100%;
    }
  }

  button {
    padding: 10px 15px;
    color: $white;
    letter-spacing: -0.03px;
    text-align: center;
    background-color: transparent;
    border: none;
    white-space: nowrap;
    cursor: pointer;

    &:focus,
    &:active {
      border: none;
      outline: 0;
    }
  }
}
