.sixtile-badge {
  font-size: 11px;
  color: white;
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding: 2.5px;
  margin-left: 10px;

  &.invalid {
    height: 20px;
    align-self: center;
    margin-right: 20px;

    background-color: $errorColor;
  }
}