.batch-common-features {
  display: flex;
  flex-direction: row;
}

.batch-modal-content.modal-content {
  display: flex;
  flex-direction: row;
  padding: 0;

  font-family: "PT Mono", monospace;
  font-size: 0.8rem;
  line-height: 1.2rem;
  min-width: 600px;




  .batch-modal-lhs,
  .batch-modal-rhs {
    width: 50%;
    flex: 1;
  }

  .batch-modal-rhs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    border-left: 0.5px solid lighten($lightest-grey, 10%);

    .tile-preview {
      height: 100%;
      display: flex;
      flex: 1;
      justify-content: center;
    }

    textarea {
      flex: 1;
      border: none;
      background: lighten($lightest-grey, 10%);
      color: $grey;
      resize: none;
      padding: 16px;

      &:focus {
        outline: none;
      }
    }
  }

  .batch-modal-lhs {
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 16px;
    // margin-right: 16px;

    .container {
      padding: 0;
    }

    .batch-modal-option,
    .form {
      margin-top: 16px;
    }

    .form {
      box-shadow: none;
      padding: 0;





      .form-group.s3Url,
      .form-group.apiUrl,
      .form-group.collectionId,
      .form-group.categoryId {
        flex-direction: column;

        .box-header {
          display: none;
        }

        .form-input-select {
          height: 38px;
          position: relative;

          .select__control {
            height: 38px;
            padding: 6px;
            justify-content: center;
            align-items: center;
          }

          .select__value-container {
            height: unset;
          }
        }
      }



      .form-group.hexColour,
      .form-group.nounHexColour,
      .form-group.textHexColour,
        {
        flex-direction: row;
        justify-content: flex-start;

        .form-label-invalid {
          display: none;
        }

        .form-input {
          display: none;
        }

        .form-label-wrapper {
          display: flex;
          margin-bottom: 0;
          width: auto;
          margin-right: 10px;
        }

        .color-picker-wrapper {
          height: 20px;

          .color-picker {
            border: none;
          }

          .top {
            width: 20px;
            height: 20px;
          }
        }
      }

      .form-label-wrapper {
        display: none;
      }
    }
  }
}

.modal-actions {

  .btn {
    background-color: $brand-secondary;
    border: none;
    color: $white;
  }

  .duplicates-label {
    flex: 1;
    margin-right: 10px;
    font-size: 14px;
    color: $grey;
  }

}