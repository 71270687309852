.form {
  background: $white;
  @include box_shadow(1);
  padding: 20px;
  border-radius: 4px;

  .imgurl,
  .imgurlDefault {
    flex-direction: row;

    align-items: center;

    .form-label-wrapper {
      width: auto;
      margin-bottom: 0;
    }

    .image {
      // flex-direction: column;
      // justify-content: flex-start;
      // align-self: flex-start;
      flex-direction: column;
      justify-content: flex-start;
      align-self: center;
    }

    .show-editor-text {
      text-transform: uppercase;
      font-family: "PT Mono", monospace;
      font-size: 0.6em;
      align-self: flex-start;
      padding: 0;
      flex: 1;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex-direction: column;
      // position: absolute;
      // top: 2px;
      // left: 50px;
    }
  }

  .box-header {
    padding: 0 0 10px 0;

    .form-label {
      margin-bottom: 0;
    }
  }
}

.form-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  h1 {
    text-align: center;
    font-family: $actionFont;
    font-size: 1.1rem;
    font-weight: 600;
  }

  .dialog-actions,
  .form-header-actions {
    display: flex;
    justify-content: flex-end;
    flex: 0.25;
    align-items: center;
  }

  .dialog-actions {
    display: flex;
    justify-content: flex-end;
    flex: 0.25;

    .btn {
      height: auto;
      width: 90px;
    }
  }
}

.form-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0;

  .btn {
    width: 80px;

    &.secondary {
      background-color: $lighter-grey;
    }
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px !important;
  position: relative;

  .form-label-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 12px;
  }

  &.collectionId,
  &.categoryId {
    form-label-wrapper {
      min-width: 86px;
    }
  }

  &.s3Url {
    flex-direction: row;
    align-items: center;

    .form-label-wrapper {
      margin-bottom: 0;
    }
  }

  // &.apiUrl,
  &.collectionId,
  &.categoryId {
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .form-label-wrapper {
      width: 100px;
    }

    .box-header {
      margin-left: 10px;
    }
  }
}

.form-label {
  text-transform: uppercase;
  font-family: $actionFont;
  font-size: 0.8em;
  align-self: center;
  padding: 0;
}

.form-label-invalid {
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  flex: 1;

  svg {
    color: red;
  }
}

.invalid-message-popover {
  color: red;
}

.form-input {
  border: 1px solid $lightest-grey;
  border-radius: 2px;
  padding: 10px 20px;
  height: 45px;
  transition: border 0.25s ease-in-out;
  background: white !important;

  &:focus {
    border: 1px solid $brand;
    outline: none;
    box-shadow: none;
  }

  &.is-danger {
    border: 1px solid $errorColor;
  }
}

.form-input-select {
  width: 100%;

  &.is-danger {
    .select__control {
      border: 1px solid $errorColor !important;
    }
  }
}

.select__placeholder {
  position: absolute;
  top: 50%;
  left: 0;
  margin: 0;
  padding: 0;
  font-family: $actionFont;
  font-size: 0.8rem;
  line-height: 1.2rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #bcc3ce !important;
}

.select__indicator {
  padding: 0 0 0 20px !important;
}

.select__clear-indicator {
  padding-right: 20px !important;
}

.select__single-value {
  font-family: $actionFont;
  font-size: 0.8rem;
  line-height: 1.2rem;
}

.select__control {
  border: 1px solid $lightest-grey !important;
  border-radius: 2px !important;
  padding: 10px 20px;
  height: 45px;
  transition: border 0.25s ease-in-out;
}

.select__control--is-focused {
  box-shadow: none !important;
  border-color: $brand !important;
}

.select__value-container {
  padding: 0 !important;
  font-size: 0.8rem;
  line-height: 1.2rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  position: relative;
}

.material-picker {
  height: auto !important;
  width: 100% !important;
  box-shadow: none !important;
}

.text-area {
  resize: vertical;
  display: block;
}

.row {
  &.vertical {
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .tile-edit {
      display: flex;
      flex-direction: column;
      align-items: center;

      .category-images {
        margin: 0;
      }
    }

    &.image {
      justify-content: "center";
      align-items: "center";
      align-content: "center";
      align-self: "center";
      position: "relative";
      display: "flex";
    }

    .image-container {
      margin-bottom: 5px;
    }
  }
}

.mui-switch {
  &.disabled {
    cursor: initial;
  }
}