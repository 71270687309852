.label-toggle {
  background: rgba(233, 239, 244, 0.48);
  padding: 5px;
  border-radius: 4px;
  display: flex;
  justify-content: stretch;
  .label {
    cursor: pointer;
    flex: 0.5;
    display: flex;
    align-items: center;
    padding: 11px;
    color: $grey;
    svg {
      color: $grey;
      margin-right: 11px;
    }
  }
  .label.active {
    color: $dark-grey;
    border-radius: 3px;
    background: #ffffff;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.03);
    svg {
      color: $brand;
    }
  }
}