.box {
  width: 100%;
  height: 100%;
  background-color: $white;
  @include box_shadow(1);
  border-radius: 4px;
  position: relative;
}

.box-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex: 1;
  width: 100%;
  .btn-action {
    svg {
      color: $brand-secondary;
    }
  }
}

.box-subtitle {
  // margin-bottom: 12px;
  text-transform: uppercase;
  font-family: $actionFont;
  font-size: 0.8em;
}
