.btn.btn-st {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: none;
  background-color: $brand-secondary;
  color: $white;
  transition: all 0.25s ease-in-out;
  align-items: center;
  margin-right: 10px;
  height: 32px;

  &:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }

  @include box_shadow(1);

  &:last-child {
    margin-right: 0;

    &.toolbar-selected-button {
      margin: 16px;
    }
  }

  &:hover {
    background-color: darken($brand-secondary, 8%);
  }

  svg {
    margin-right: 10px;
  }
}

.btn.btn-warning {
  background-color: $warningColor;

  &:hover {
    background-color: darken($warningColor, 8%);
  }
}

.btn.btn-save {
  &.disable {
    opacity: 0.5;
  }
}

.btn.btn-error {
  background-color: $errorColor;

  &:hover {
    background-color: darken($errorColor, 8%);
  }

  &.hide {
    display: none;
  }
}

.btn.btn-icon-only {
  justify-content: center;
  min-width: 32px;
  border-radius: 50px;

  svg {
    margin-right: 0;
  }

  &.btn-ghost {
    border-radius: 50px;
    height: 32px;
    padding: 0;
  }
}

.switch-primary-color {
  background-color: 'green';
}

.btn.btn-transparent {
  background: none;
  box-shadow: none;
  padding: 0;

  &:hover {
    background: none;
    box-shadow: none;

    svg {
      color: $dark-grey;
    }
  }

  svg {
    color: $grey;
    font-size: 18px;
  }
}

.btn.btn-ghost {
  background: $lightest-grey;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: none;
  color: $lighter-grey;
  transition: all 0.25s ease-in-out;
  align-items: center;
  margin-right: 8px;
  box-shadow: none;
  padding: 11px;
  height: auto;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: $lighter-grey;
    color: $grey;
    @include box_shadow(1);
  }
}
