@import url('https://fonts.googleapis.com/css?family=PT+Mono|PT+Sans:400,700');
$actionFont: 'PT Mono',
monospace;
$contentFont: 'PT Sans',
sans-serif;

body,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $contentFont !important;
  font-weight: normal !important;
}

button,
input,
textarea,
th {
  font-family: $actionFont !important;
  font-weight: normal !important;
}